import React from 'react';
import { FormGroup, FormText, Label } from 'reactstrap';
import DatePickerCustom from './DatePickerCustom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FaAsterisk } from 'react-icons/fa';

const datePickerCustomFormGroup = (props) => {
  return (
    <FormGroup>
      <p className="debug">{props.field.dataType}/{props.field.fieldType}</p>
      <Label for={'survey-field-' + props.field.fieldKey}>{props.field.fieldLabel} {props.field.validation.required ?
        <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
        <DatePickerCustom
          date={{value: moment().format('YYYY-MM-DD')}}
          field={props.field}
          id={props.id}
          onChange={props.onChange}
          selected={props.selected}/>
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

datePickerCustomFormGroup.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object
};

export default datePickerCustomFormGroup;
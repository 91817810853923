import React from 'react';
import {FormGroup, FormText, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import { FaAsterisk } from 'react-icons/fa';

const select = (props) => {
  // we can except option parameters from an external source or from predefined options contained in field row in db
  const optionKeyValues = props.options || props.field.options;
  const options = Object.keys( optionKeyValues )
    .map( igKey => {
      return <option key={igKey} value={igKey}>{optionKeyValues[igKey]}</option>
    });
  return (
    <FormGroup className={props.fieldData.touched && !props.fieldData.valid ? 'alert alert-danger' : ''}>
      <p className="debug">{props.field.dataType}/{props.field.fieldType}</p>
      <Label for={'survey-field-' + props.field.fieldKey}>{props.field.fieldLabel} {props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
      <Input id={'survey-field-' + props.field.fieldKey}
             invalid={props.fieldData.touched && !props.fieldData.valid}
             onChange={(event) => props.changed(props.field.fieldKey, event.target.value)}
             type="select"
             readOnly={props.readOnly}
             valid={props.fieldData.touched && props.fieldData.valid}
             value={props.fieldData.value}>
        <option value="" disabled>{props.field.placeholder}</option>
        {options}
      </Input>
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

select.propTypes = {
  changed: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  fieldData: PropTypes.object.isRequired,
  options: PropTypes.object,
  readOnly: PropTypes.bool
};

select.defaultProps = {
  options: {},
  readOnly: false
};

export default select;
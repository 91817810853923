import React, {Component} from 'react';
import {FormGroup, Row, Col, Input, Label, FormText, Button} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePickerCustom from '../../../DatePickerCustom/DatePickerCustom';

class Revenue extends Component {

  render() {
    const idPrefix = 'survey-field-' + this.props.fieldKey;

    return (
      <FormGroup>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for={`${idPrefix}-date-${this.props.index}`}>Date *</Label>
              <DatePickerCustom date={this.props.date}
                                id={`${idPrefix}-date-${this.props.index}`}
                                onChange={(date) => this.props.changed(this.props.fieldKey, moment(date).format('YYYY-MM-DD'), this.props.index, 'date')}
                                selected={moment(this.props.date.value).toDate()}/>
              <FormText color="muted">Date for Revenue (select today)</FormText>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className={this.props.revenue.touched && !this.props.revenue.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-revenue-${this.props.index}`}>Revenue *</Label>
              <Input id={`${idPrefix}-revenue-${this.props.index}`}
                     invalid={this.props.revenue.touched && !this.props.revenue.valid}
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'revenue')}
                     placeholder="Total Revenue"
                     type="number"
                     value={this.props.revenue.value}
                     valid={this.props.revenue.touched && this.props.revenue.valid} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for={`${idPrefix}-currency-${this.props.index}`}>Currency *</Label>
              <Input id={`${idPrefix}-currency-${this.props.index}`}
                     invalid={this.props.currency.touched && !this.props.currency.valid}
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'currency')}
                     placeholder="Currency"
                     type="select"
                     value={this.props.currency.value}
                     valid={this.props.currency.touched && this.props.currency.valid}>
                <option value="" disabled>Choose currency</option>
                <option value="CAD">CAD</option>
                <option value="USD">USD</option>
              </Input>
              <FormText color="muted">Currency</FormText>
            </FormGroup>
          </Col>
          <Col md={1} className="text-center">
            <Button close style={{float: 'none'}} title="Remove field" className="h-100" onClick={() => this.props.removeField(this.props.fieldKey, this.props.index)}/>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

Revenue.propTypes = {
  changed: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fieldKey: PropTypes.string.isRequired,
  revenue: PropTypes.object.isRequired,
  removeField: PropTypes.func.isRequired
};

export default Revenue;
import React, { Component } from 'react';
import axios from "../../../axios";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import {FormText, FormGroup, Label} from "reactstrap";
import AddressAutocompleteItem from "./AddressAutocompleteItem/AddressAutocompleteItem";
import { FaAsterisk } from 'react-icons/fa';

class AddressAutocomplete extends Component {

  state = {
    isLoading: false,
    options: [],
  };

  componentDidUpdate() {
    this.props.handleSetTypeaheadRef(this.typeahead.getInstance());
  }

  render() {
    const selectHandler = this.props.selected ? (selected) => {
      if (selected.length === 0) {
        return () => {};
      }
      // call the selected callback with the company object
      return this.props.selected(selected[0])
    } : () => {};

    let color = 'inherit';
    if (this.props.fieldData.touched && this.props.fieldData.valid) {
      color = '#28a745';
    }
    if (this.props.fieldData.touched && !this.props.fieldData.valid) {
      color = '#dc3545';
    }

    return (
      <FormGroup className={this.props.fieldData.touched && !this.props.fieldData.valid ? 'alert alert-danger' : ''}>
        <Label style={{color: color}} for="survey-field-company-profile-name">{this.props.field.fieldLabel} {this.props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : ''}</Label>
        <AsyncTypeahead
          {...this.state}
          delay={1000}
          id="survey-field-company-profile-address-autocomplete"
          isInvalid={this.props.fieldData.touched && !this.props.fieldData.valid}
          isValid={this.props.fieldData.touched && this.props.fieldData.valid}
          labelKey="title"
          minLength={3}
          onSearch={this.handleSearch}
          onChange={selectHandler}
          onInputChange={(value) => this.props.changed(this.props.field.fieldKey, value)}
          placeholder="Enter your address"
          ref={(ref) => this.typeahead = ref}
          renderMenuItemChildren={(option, props) => (
            <AddressAutocompleteItem key={option.id} item={option} />
          )}
          value={this.props.fieldData.value}
        />
        <FormText color="muted">
          Please begin entering your company's address and then select your address from the dropdown list.{' '}
          <span className="text-primary"
                style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={this.props.showAddressFields}>Can't find your address?</span>
        </FormText>
      </FormGroup>
    );
  }

  handleSearch = (query) => {
    this.setState({isLoading: true});
    axios.get( `/places?input=${query}&sessionToken=${this.props.sessionToken}` )
      .then( response => {
        //console.log(response.data.data.attributes.map(item => { return {name: item.description, id: item.place_id}}));
        this.setState({

          isLoading: false,
          //options: response.data.data.attributes.map(item => { return {label: item.title, value: item.id}})
          options: response.data.data.attributes.map(item => { return {
            title: item.description,
            id: item.place_id,
            mainText: item.mainText,
            secondaryText: item.secondaryText
          }})
        });
      } )
      .catch( error => {
        //dispatch(fetchSurveyFailure(error));
        console.error(error);
      } );

  };
}

export default AddressAutocomplete;
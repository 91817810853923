import React from 'react';
import {FormGroup, FormText, Input, Label} from "reactstrap";
import { FaAsterisk } from 'react-icons/fa';

const checkboxMultiSelect = (props) => {

  let color = 'inherit';
  if (props.field.touched && props.field.valid) {
    color = '#28a745';
  }
  if (props.field.touched && !props.field.valid) {
    color = '#dc3545';
  }

  const checkboxes = Object.keys(props.field.options).map(
    key => (
      <FormGroup check key={key}>
        <Input id={'survey-field-' + props.field.fieldKey + '-' + key}
               invalid={props.fieldData.touched && !props.fieldData.valid}
               onChange={(event) => props.changed(props.field.fieldKey, event.target.value)}
               placeholder={props.field.placeholder}
               type="checkbox"
               valid={props.fieldData.touched && props.fieldData.valid}
               checked={props.fieldData.value.indexOf(props.field.options[key]) > -1}
               value={props.field.options[key]}/>
        <Label check for={'survey-field-' + props.field.fieldKey + '-' + key}>{props.field.options[key]}</Label>
      </FormGroup>
    )
  );

  return (
    <FormGroup tag="fieldset" className={props.fieldData.touched && !props.fieldData.valid ? 'alert alert-danger' : ''}>
      <div className="debug">Touched: {props.field.touched ? 'YES' : 'NO' } Valid: {props.field.valid ? 'YES' : 'NO' }</div>
      <Label style={{color: color}}>{props.field.fieldLabel} {props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
      {checkboxes}
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

export default checkboxMultiSelect;
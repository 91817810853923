import React from 'react';

const heading = (props) => {
  return (
    <div>
      <p className="debug">{props.field.dataType}/{props.field.fieldType}</p>
      <h2>{props.field.fieldLabel}</h2>
      <p><em>{props.field.helpText}</em></p>
    </div>
  );
};

export default heading;
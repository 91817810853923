import React from 'react';
import Loader from "react-loader-spinner";

const spinner = (props) => {
  let color = '#231f20';
  if (props.dark === true) {
    color = '#231f20';
  }
  if (props.light === true) {
    color = '#ffffff';
  }
  return (
    <section style={{textAlign: 'center'}}>
      <Loader type="ThreeDots" color={color} width={24} height={24}/>
    </section>
  );
};

export default spinner;
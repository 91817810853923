import React, { Component } from 'react';
import { Table, Alert, Button } from 'reactstrap';
import MilestoneRow from './MilestoneRow/MilestoneRow';
import Spinner from '../../components/Spinner/Spinner';

class MilestonesTable extends Component {
  state = {
    showMore: false
  };

  toggleMore = () => {
    this.setState( { showMore: !this.state.showMore } );
  };

  render() {
    let milestoneRows = null;
    let moreButton = null;

    // map milestones to MilestoneRow components
    if (this.props.loading) {
      moreButton = null;
      milestoneRows = this.props.loading ? <tr>
        <td colSpan="5" style={{textAlign: 'center'}}><Spinner dark/></td>
      </tr> : '';
    } else if (this.props.error) {
      moreButton = null;
      milestoneRows = this.props.error ? <tr>
        <td colSpan="5"><Alert color="danger">Error</Alert></td>
      </tr> : '';
    } else {
      moreButton = (
        <tr>
          <td colSpan="5" style={{textAlign: 'center'}}>
            <Button outline
                    size="sm"
                    color="link"
                    onClick={this.toggleMore}>{this.state.showMore ? 'Show Less' : `Show all (${this.props.milestones.length})` }</Button>
          </td>
        </tr>
      );

      // if showMore is enabled then we do not filter any milestones
      if (this.state.showMore) {
        milestoneRows = Object.keys(this.props.milestones)
          .map(key => <MilestoneRow key={key} milestone={this.props.milestones[key]}/>);
      } else {
        // filter out all milestones older than 1 year
        // milestoneRows = Object.keys(this.props.milestones)
        //   .filter(key => this.props.milestones[key].attributes.date >= moment().subtract(1, 'years').format('YYYY-MM-DD'))
        //   .map(key => <MilestoneRow key={key} milestone={this.props.milestones[key]}/>);

        // if we filtered out all the milestones put 5 back
        milestoneRows = Object.keys(this.props.milestones.slice(0,5))
          .map(key => <MilestoneRow key={key} milestone={this.props.milestones[key]}/>);

        // hide the more button if we have less than 5 items
        if (this.props.milestones.length <= 5 ) {
          moreButton = null;
        }
      }
    }


    return (
      <Table className="milestones-table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Entry Type</th>
          <th>Category</th>
          <th>Sub-category</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        {milestoneRows}
        {moreButton}
        </tbody>
      </Table>
    );
  }
}

export default MilestonesTable;
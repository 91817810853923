import React, {Component} from 'react';
import {FormGroup, Row, Col, Input, Label, FormText, Button} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePickerCustom from '../../../DatePickerCustom/DatePickerCustom';


class Ip extends Component {

  render() {
    const idPrefix = 'survey-field-' + this.props.fieldKey;
    const options = Object.keys( this.props.types )
      .map( igKey => {
        return <option key={igKey} value={igKey}>{this.props.types[igKey]}</option>
      });

    return (
      <FormGroup>
        <Row form>
          <Col md={3}>
            <FormGroup>
              <Label for={`${idPrefix}-date-${this.props.index}`}>Date *</Label>
              <DatePickerCustom date={this.props.date}
                                id={`${idPrefix}-date-${this.props.index}`}
                                onChange={(date) => this.props.changed(this.props.fieldKey, moment(date).format('YYYY-MM-DD'), this.props.index, 'date')}
                                selected={moment(this.props.date.value).toDate()}/>
              <FormText color="muted">IP Date (enter the date for when the IP was filed)</FormText>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className={this.props.filingNumber.touched && !this.props.filingNumber.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-filing-number-${this.props.index}`}>IP Filing Number *</Label>
              <Input type="text"
                     id={`${idPrefix}-filing-number-${this.props.index}`}
                     invalid={this.props.filingNumber.touched && !this.props.filingNumber.valid}
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'filingNumber')}
                     placeholder="IP Filing Number"
                     value={this.props.filingNumber.value}
                     valid={this.props.filingNumber.touched && this.props.filingNumber.valid} />
              <FormText color="muted">Enter in all IP that has been filed</FormText>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className={this.props.patentType.touched && !this.props.patentType.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-patent-type-${this.props.index}`}>Patent Type *</Label>
              <Input id={`${idPrefix}-patent-type-${this.props.index}`}
                     invalid={this.props.patentType.touched && !this.props.patentType.valid}
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'patentType')}
                     placeholder="Patent Type"
                     type="select"
                     value={this.props.patentType.value}
                     valid={this.props.patentType.touched && this.props.patentType.valid}>
                  <option value="" disabled>Choose a patent type</option>
                  {options}
              </Input>
              <FormText color="muted">The type of patent</FormText>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup className={this.props.country.touched && !this.props.country.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-country-${this.props.index}`}>Country *</Label>
              <Input id={`${idPrefix}-country-${this.props.index}`}
                     invalid={this.props.country.touched && !this.props.country.valid}
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'country')}
                     placeholder="Country"
                     type="select"
                     value={this.props.country.value}
                     valid={this.props.country.touched && this.props.country.valid}>
                <option value="" disabled>Choose a country</option>
                <option value="Canada">Canada</option>
                <option value="USA">USA</option>
              </Input>
              <FormText color="muted">Country where IP was filed.</FormText>
            </FormGroup>
          </Col>
          <Col md={1} className="text-center">
            <Button close style={{float: 'none'}} title="Remove field" className="h-100" onClick={() => this.props.removeField(this.props.fieldKey, this.props.index)}/>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

Ip.propTypes = {
  changed: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fieldKey: PropTypes.string.isRequired,
  filingNumber: PropTypes.object.isRequired,
  patentType: PropTypes.object.isRequired,
  removeField: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
};

export default Ip;
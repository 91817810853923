import communitechLogo from './assets/images/branding/communitech/logo.png';
import cdmnLogo from './assets/images/branding/cdmn/logo.png';
import cdmnHeader from './assets/images/branding/cdmn/header.jpg';
import communitechHeader from './assets/images/branding/communitech/header.png';

export const branding = {
  communitech: {
    logo: communitechLogo,
    logoClassNames: ['rounded-circle'],
    header: communitechHeader,
    title: 'Communitech'
  },
  cdmn: {
    logo: cdmnLogo,
    logoClassNames: [],
    header: cdmnHeader,
    title: 'CDMN'
  }
};
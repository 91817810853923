import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Survey from './containers/Survey/Survey';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="app">
        <Switch>
          <Route path="/:surveyHash" exact component={Survey} />
          <Route path="/:surveyHash/preview" component={Survey} />
          <Route path='/' component={() => {
            window.location.href = 'https://communitech.ca';
            return null;
          }}/>
        </Switch>
      </div>
    );
  }
}

export default App;

import React from 'react';
import { FormGroup, FormText, Col, Row, Input, Label } from 'reactstrap';
import { FaAsterisk } from 'react-icons/fa';
//import './Milestone.css';

const ratingRadio = (props) => {

  let color = 'inherit';
  if (props.fieldData.touched && props.fieldData.valid) {
    color = '#28a745';
  }
  if (props.fieldData.touched && !props.fieldData.valid) {
    color = '#dc3545';
  }

  const checkboxes = Object.keys(props.field.options).map(
    key => (
      <FormGroup check inline key={key}  className={props.fieldData.touched && !props.fieldData.valid ? 'alert alert-danger' : ''}>
        <div style={{display:'block', textAlign: 'center'}}>
          <Label check
                 for={'survey-field-' + props.field.fieldKey + '-' + key}
                 style={{paddingRight: '4px'}}>{props.field.options[key]}</Label>
        <br/>
        <Input checked={props.fieldData.value === props.field.options[key]}
               id={'survey-field-' + props.field.fieldKey + '-' + key}
               invalid={props.fieldData.touched && !props.fieldData.valid}
               name={'survey-field-' + props.field.fieldKey}
               onChange={(event) => props.changed(props.field.fieldKey, event.target.value)}
               placeholder={props.field.placeholder}
               style={{marginTop: '1em'}}
               type="radio"
               valid={props.fieldData.touched && props.fieldData.valid}
               value={props.field.options[key]}/>
        </div>
      </FormGroup>
      )
  );

  return (
    <FormGroup tag="fieldset">
      <div className="debug">Touched: {props.field.touched ? 'YES' : 'NO' } Valid: {props.field.valid ? 'YES' : 'NO' }</div>
      <Label style={{color: color}}>{props.field.fieldLabel} {props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
      <Row>
        {/*<Col md={3} style={{textAlign: 'center'}}>*/}
        {/*  <span style={{verticalAlign: 'bottom'}}>Not Satisfied</span> &nbsp;*/}
        {/*</Col>*/}
        <Col md={12} style={{textAlign: 'left'}}>
          {checkboxes}
        </Col>
        {/*<Col md={3} style={{textAlign: 'center'}}>*/}
        {/*  <span style={{verticalAlign: 'bottom'}}>Very Satisfied</span> &nbsp;*/}
        {/*</Col>*/}
      </Row>
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

export default ratingRadio;
import React from 'react';
import { Alert } from 'reactstrap';
import Spinner from '../../components/Spinner/Spinner';
import './Header.css';
import { branding } from '../../branding';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import moment from 'moment';

const HeaderStyled = styled('div')`
  :before {
    background-color: blue;
    background-image: ${props => `url(${props.headerImage})`};
  }
`;

const header = (props) => {

  //if this is a survey then show company name and reporting period
  let companyName = props.companyName ? <span className="header__company">Reporting for {props.companyName}</span> : '';
  let reportingPeriod = '';
  if (props.periodStart && props.periodEnd) {
    reportingPeriod = <div className="header__period">{props.periodStart} to {props.periodEnd}</div>;
  }
  const error = props.error ? <Alert color="danger" className="header__error">{props.error ? 'Error retrieving survey data' : ''}</Alert> : '';

  let logoClassNames;
  if (props.branding) {
    document.title = branding[props.branding].title;
    logoClassNames = branding[props.branding].logoClassNames.slice(0);
    logoClassNames.push('header__logo');
  }

  const expired = props.expires && moment.utc(props.expires) <= moment.utc();

  return (
    <div>
      {
        props.branding
        ?
          <HeaderStyled className="container-fluid header" headerImage={props.branding ? branding[props.branding].header : null}>
            <header>
              <img src={branding[props.branding].logo} className={classNames(logoClassNames)} alt={`${branding[props.branding].title} - Logo`}/>
              <h1 className="header__title">{props.loading ? <Spinner light/>  : props.name}</h1>
              {error}
              {companyName}
              {reportingPeriod}
              {props.previewMode ? <Alert color="warning">This survey is in preview mode and cannot be submitted.</Alert> : null}
              {expired ? <Alert color="danger">This survey expired on {moment.utc(props.expires).local().format('lll')}</Alert> : null}
            </header>
          </HeaderStyled>
        : null
      }
    </div>
  );
};

header.propTypes = {
  branding: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  expires: PropTypes.string,
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string,
  previewMode: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object
};

header.defaultProps = {
  companyName: null,
  error: null,
  periodStart: null,
  periodEnd: null
};

export default header;
import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchMilestonesStart = () => {
  return {
    type: actionTypes.FETCH_MILESTONES_START
  };
};

export const fetchMilestones = (surveyHash) => {
  return dispatch => {
    dispatch(fetchMilestonesStart());
    axios.get( '/milestones/' + surveyHash )
      .then( response => {
        dispatch(fetchMilestonesSuccess(response.data));
      } )
      .catch( error => {
        dispatch(fetchMilestonesFailure(error));
      } );
  };
};

export const fetchMilestonesSuccess = ( data ) => {
  return {
    type: actionTypes.FETCH_MILESTONES_SUCCESS,
    data: data
  };
};

export const fetchMilestonesFailure = ( error ) => {
  return {
    type: actionTypes.FETCH_MILESTONES_FAILURE,
    error: error
  };
};

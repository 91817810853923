import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import {connect} from "react-redux";
import Spinner from "../../components/Spinner/Spinner";

class SelectCompanyConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.isOpen
    };

    // this.toggle = this.toggle.bind(this);
  }

  // toggle() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }


  render() {

    // because I really want city and province to have a comma
    const cityProvince = [];
    if (this.props.company.city) {
      cityProvince.push(this.props.company.city);
    }
    if (this.props.company.province) {
      cityProvince.push(this.props.company.province);
    }

    const country = this.props.company.country ? <div>{this.props.company.country}</div> : null;
    const websiteUrl = this.props.company.websiteUrl ? <div>{this.props.company.websiteUrl}</div> : null;
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose} className={this.props.className}>
          <ModalHeader toggle={this.props.onClose}>Is this your company?</ModalHeader>
          <ModalBody>
            {
              this.props.selectCompany.error
                ? <Alert color="danger">{
                  typeof this.props.selectCompany.error.message !== 'undefined'
                    ? this.props.selectCompany.error.message
                    : 'Could not select company.'
                }</Alert>
                : null
            }
            <p>Are you sure this is your company?</p>
            <address>
              <strong>Company Details:</strong><br/>
              {this.props.company.name}<br/>
              {cityProvince.join(', ')}
              {country}
              {websiteUrl}
            </address>
            <p>Once you click the confirmation button below you cannot change your selection.</p>
            <p>All founders/co-founders will receive an email with a link to continue this application.</p>
            {this.props.previewMode ? <Alert color="warning">Survey is in preview mode. Company selection is disabled.</Alert> : null}
          </ModalBody>
          <ModalFooter>
            {this.props.selectCompany.loading ? <Spinner/> : '' }
            <Button color="primary" disabled={this.props.selectCompany.loading || this.props.previewMode} onClick={() => this.props.onConfirmed(this.props.company)}>Yes, this is my company</Button>{' '}
            <Button color="secondary" onClick={this.props.onClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectCompany: state.selectCompany
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //onFetchOrders: () => dispatch( actions.fetchOrders() )
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectCompanyConfirm);

import React from 'react';
//import './Milestone.css';

const milestoneRow = (props) => {
  return (
    <tr className="milestone">
      <td>{props.milestone.attributes.date}</td>
      <td>{props.milestone.attributes.entryType}</td>
      <td>{props.milestone.attributes.category}</td>
      <td>{props.milestone.attributes.subCategory}</td>
      <td>{props.milestone.attributes.value}</td>
    </tr>
  );
};

export default milestoneRow;
import React from 'react';
import {FormGroup, FormText, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import { FaAsterisk } from 'react-icons/fa';

const textInput = (props) => {
  return (
    <FormGroup className={props.fieldData.touched && !props.fieldData.valid ? 'alert alert-danger' : ''}>
      <p className="debug">{props.field.dataType}/{props.field.fieldType}</p>
      <Label for={'survey-field-' + props.field.fieldKey}>{props.field.fieldLabel} {props.field.validation.required ?
        <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
      <Input id={'survey-field-' + props.field.fieldKey}
             invalid={props.fieldData.touched && !props.fieldData.valid}
             placeholder={props.inputType === 'number' ? 'Value must be a number' : props.field.placeholder}
             onChange={event => props.changed(props.field.fieldKey, event.target.value)}
             onKeyDown={event => {
               if (props.inputType === 'number' && (event.which === 69 || event.which === 189 || event.which === 187)) {
                 event.preventDefault();
               }
             }}
             type={props.inputType || props.field.fieldType}
             valid={props.fieldData.touched && props.fieldData.valid}
             value={props.fieldData.value}>
      </Input>
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

textInput.propTypes = {
  changed: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  fieldData: PropTypes.object.isRequired,
  inputType: PropTypes.string,
};

export default textInput;
import React, { Component } from 'react';
import {FormGroup, FormText, Input, Label} from "reactstrap";

class PreQuestion extends Component {
  state = {
    showField: false
  };

  handleChange = (value) => {
    this.setState( { showField: value === 'yes' } );
  };
  
  componentDidMount() {
    this.props.validateForm();
  }

  render() {
    return (
      <FormGroup tag="fieldset">
        {this.props.field.preQuestionLabel}
        <FormGroup check>
          <Input checked={this.state.showField}
                 type="radio"
                 id={'survey-field-' + this.props.field.fieldKey + '-yes-pq'}
                 name={'survey-field-' + this.props.field.fieldKey + '-pq'}
                 placeholder={this.props.field.placeholder}
                 onChange={(event) => this.handleChange(event.target.value)}
                 value="yes">
          </Input>
          <Label check for={'survey-field-' + this.props.field.fieldKey + '-yes-pq'}>Yes</Label>
        </FormGroup>
        <FormGroup check>
          <Input checked={!this.state.showField}
                 type="radio"
                 id={'survey-field-' + this.props.field.fieldKey + '-no-pq'}
                 name={'survey-field-' + this.props.field.fieldKey + '-pq'}
                 placeholder={this.props.field.placeholder}
                 onChange={(event) => this.handleChange(event.target.value)}
                 value="no">
          </Input>
          <Label check for={'survey-field-' + this.props.field.fieldKey + '-no-pq'}>No</Label>
        </FormGroup>
        <FormText color="muted">{this.props.field.preQuestionHelpText}</FormText>
        {this.state.showField ? this.props.children : ''}
      </FormGroup>
    );
  }
}

export default PreQuestion;
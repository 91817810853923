import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import SurveyFields from '../SurveyFields/SurveyFields';
import * as actions from '../../store/actions/';
import MilestonesTable from '../../components/MilestonesTable/MilestonesTable';
import './Survey.css';
import SelectCompanyConfirm from '../SelectCompanyConfirm/SelectCompanyConfirm';
import uuidv4 from 'uuid/v4';
import { cloneDeep, isEmpty } from 'lodash';
import { Container } from 'reactstrap';
import moment from 'moment';

class Survey extends Component {

  state = {
    showSelectCompanyConfirmModal: false,
    company: {},
    companyNameTypeaheadRef: null,
    clear: false,
    sessionToken: uuidv4(),
    selectCompanyCloseCallback: null
  };

  componentDidMount() {
    const surveyHash = this.props.match.params.surveyHash;
    const previewMode = this.props.match.path.substr(-8) === '/preview';
    this.props.onInitSurvey(surveyHash, previewMode);
    this.props.onInitSurveyFields(surveyHash, previewMode);
    this.props.onInitLists(surveyHash);
    this.props.onInitMilestones(surveyHash);
  }

  handleSubmitSurvey = (formData) => {
    formData = cloneDeep(formData);
    const surveyHash = this.props.match.params.surveyHash;

    // remove touched, valid properties from formData
    // change from {
    //   "traction-employment": [
    //     {
    //       "classification: {
    //         "value": "full-time",
    //         "touched": false,
    //         "valid":false
    //       },
    //       ...
    //     },
    //     ...
    //   ],
    //   ...
    // }
    //
    // change to {
    //   "traction-employment": [
    //     {
    //       "classification": "full-time",
    //       ...
    //     },
    //     ...
    //   ],
    //   ...
    // }
    for (let key in formData) {
      if (formData[key].value === undefined) {
        for (let index in formData[key]) {
          for (let subKey in formData[key][index]) {
            //console.log(subKey, formData[key][index][subKey].value);
            formData[key][index][subKey] = formData[key][index][subKey].value;
          }
        }
      } else {
        formData[key] = formData[key].value;
      }
    }
    //console.log('SUBMIT', formData);

    this.props.onSubmitSurvey(surveyHash, formData);
  };

  handleSelectCompany = (company, closeCallback) => {
    this.setState({
      showSelectCompanyConfirmModal: true,
      company: company,
      selectCompanyCloseCallback: closeCallback
    });
  };

  handleSelectCompanyClose = () => {
    if (typeof this.state.selectCompanyCloseCallback !== 'undefined') {
      this.state.selectCompanyCloseCallback();
    }
    this.setState({showSelectCompanyConfirmModal: false, clear: true});
  };

  handleSelectCompanyConfirm = (company, x) => {
    //console.log(company);
    const surveyHash = this.props.match.params.surveyHash;
    this.props.onSelectCompany(surveyHash, company.communitechCompanyId);
  };

  handleValidateSurveyField = (field) => {
    this.props.onValidateSurveyField(field);
  };

  handleTypeaheadReference = (ref) => {
    if (this.state.clear) {
      ref.clear();
      this.setState({clear: false});
    }
  };

  render() {
    //const surveyHash = this.props.match.params.surveyHash;
    let milestones = '';
    if (this.props.survey.data.type === 'survey') {
      milestones = (
        <MilestonesTable milestones={this.props.milestones.data}
                         error={this.props.milestones.error}
                         loading={this.props.milestones.loading}/>
      );
    }
    const previewMode = this.props.match.path.substr(-8) === '/preview';
    const survey = this.props.survey.data.attributes;
    const surveyMessage = survey.message ? <div className="survey__message" dangerouslySetInnerHTML={{__html: survey.message }} /> : '';
    let surveyBody = '';
    //console.log('SURVEY.submitSurvey', this.props.submitSurvey);

    const thankYouMessage = !isEmpty(survey.thankYouMessage) ? survey.thankYouMessage : '<p>Your submission has been received.</p>';

    if (this.props.selectCompany.selected && survey.submitted === false) {
      surveyBody = (
        <section className="container survey__body">
          <h1>Thank you!</h1>
          <p>Please check your email to continue this application.</p>
        </section>
      );
    } else if (survey.submitted) {
      surveyBody = (
        <section className="container survey__body">
          <h1>Thank you!</h1>
          <div dangerouslySetInnerHTML={{__html: thankYouMessage}}/>
          {this.props.submitSurvey.responseId ?
            <p><small>Confirmation ID: {this.props.submitSurvey.responseId}</small></p> : ''}
        </section>
      );
    } else if (!this.props.selectCompany.selected && !this.props.submitSurvey.submitted) {
      const expired = survey.expires && moment.utc(survey.expires) <= moment.utc();
      if (expired && !previewMode) {
        surveyBody = <section className="container survey__body">
          <h1 className="text-center">Sorry, this survey has expired</h1>
        </section>
      } else {
        surveyBody = (
          <section className="container survey__body">
            {surveyMessage}
            {milestones ? <h2>Previous Milestones</h2> : ''}
            {milestones}
            <SurveyFields company={survey.company}
                          surveyFields={this.props.surveyFields}
                          survey={this.props.survey}
                          sessionToken={this.state.sessionToken}
                          lists={this.props.lists.data.attributes}
                          handleValidateSurveyField={this.handleValidateSurveyField}
                          handleSelectCompany={this.handleSelectCompany}
                          handleCompanyNameSetTypeaheadRef={this.handleTypeaheadReference}
                          handleSurveySubmit={this.handleSubmitSurvey}
                          previewMode={previewMode}/>
            <SelectCompanyConfirm
              isOpen={this.state.showSelectCompanyConfirmModal}
              company={this.state.company}
              onConfirmed={this.handleSelectCompanyConfirm}
              onClose={this.handleSelectCompanyClose}
              previewMode={previewMode}/>
          </section>
        );
      }
    } else {
      surveyBody = (
        <section className="container survey__body">
          <h1>Thank you!</h1>
          <div dangerouslySetInnerHTML={{__html: thankYouMessage }}/>
          {this.props.submitSurvey.responseId ? <p><small>Confirmation ID: {this.props.submitSurvey.responseId}</small></p> : ''}
        </section>
      );
    }

    return (
      <div className="survey">
        <Header branding={survey.branding}
                companyName={survey.company ? survey.company.name : null}
                expires={survey.expires}
                periodStart={survey.periodStart}
                periodEnd={survey.periodEnd}
                previewMode={this.props.match.path.substr(-8) === '/preview'}
                name={survey.name}
                loading={this.props.survey.loading}
                error={this.props.survey.error}/>
        {surveyBody}
        <footer>
          <br/>
          <Container className="mt-3">
            <p className="text-muted text-center">
              <small>&copy; {moment().format('YYYY')} Communitech Corporation. All rights reserved.</small>
              <br/><small>version: {process.env.REACT_APP_CURRENT_GIT_SHA}</small>
            </p>
          </Container>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lists: state.lists,
    milestones: state.milestones,
    survey: state.survey,
    surveyFields: state.surveyFields,
    selectCompany: state.selectCompany,
    submitSurvey: state.submitSurvey,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitLists: (surveyHash) => dispatch(actions.fetchLists(surveyHash)),
    onInitMilestones: (surveyHash) => dispatch(actions.fetchMilestones(surveyHash)),
    onInitSurvey: (surveyHash, previewMode) => dispatch(actions.fetchSurvey(surveyHash, previewMode)),
    onInitSurveyFields: (surveyHash, previewMode) => dispatch(actions.fetchSurveyFields(surveyHash, previewMode)),
    onSubmitSurvey: (surveyHash, submission) => dispatch(actions.submitSurveyInit(surveyHash, submission)),
    onSelectCompany: (surveyHash, companyId) => dispatch(actions.selectCompanySubmit(surveyHash, companyId)),
    onValidateSurveyField: (field) => dispatch(actions.validateSurveyField(field)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);

import React, { Component } from 'react';
import {Form, Button, Alert, Input, Label, FormGroup, UncontrolledTooltip } from 'reactstrap';
import axios from '../../axios';
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import isEmptyObject from 'is-empty-object';
import { connect } from 'react-redux';

import AddressAutocomplete from './AddressAutocomplete/AddressAutocomplete';
import CompanyName from '../../containers/SurveyFields/CompanyName/CompanyName';
import Checkbox from './Checkbox/Checkbox';
import CheckboxMultiSelect from './CheckboxMultiSelect/CheckboxMultiSelect';
import Heading from './Heading/Heading';
import PreQuestion from './PreQuestion/PreQuestion';
import RatingRadio from './RatingRadio/RatingRadio';
import SelectRadio from './SelectRadio/SelectRadio';
import Select from './Select/Select';
import Spinner from "../../components/Spinner/Spinner";
import TextInput from './TextInput/TextInput';
import TractionGroup from './Traction/TractionGroup/TractionGroup';
import './SurveyFields.css';
import DatePickerCustomFormGroup from './DatePickerCustom/DatePickerCustomFormGroup';
import { FaAsterisk } from 'react-icons/fa';
import Attachment from './Attachment/Attachment';
import TextInputWysiwyg from './TextInputWysiwyg/TextInputWysiwyg'
//Form test data
//import {TestFormData} from '../TestFormData/Survey2';
//import {updateObject} from "../../store/utility";



class SurveyFields extends Component {

  state = {
    formIsValid: false,
    formData: {},
    showAddressFields: false
  };

  /**
   * Validates an individual field
   *
   * @param field  Field data object that defines how the field should be displayed
   * @param value
   * @returns {boolean}
   */
  checkValidity(field, value, subKey) {
    let isValid = true;
    //console.log(`checkValidity fieldKey="${field.fieldKey}", value="${value}", fieldType="${field.fieldType}", dataType="${field.dataType}"`);
    // if we didn't provided field data or there is not field validation specified then return valid
    if (field === undefined || field.validation === undefined) {
      return true;
    }

    if (field.fieldType === 'company-profile-address-autocomplete') {
      // validation is handled elsewhere
      return true;
    }


    // pull out the validation rules
    const rules = field.validation;

    // handle is required validation rule
    if (rules.required) {
      if (field.fieldType === 'checkbox-multi-select') {
        // value should be an array
        isValid = value.length > 0 && isValid;
      } else if (field.fieldType === 'checkbox' || field.dataType === 'tag') {
        isValid = value === true && isValid;
      } else {
        //console.log('VALUE', value);
        value = value === null ? '' : value;
        if (typeof value.trim === 'function') {
          isValid = value.trim() !== '' && isValid;
        } else {
          isValid = value !== '' && isValid;
        }
        //console.log('VALUE2', value);
      }
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid
    }

    // if survey type is survey and subKey is date
    if (this.props.survey.data.attributes.type === 'survey') {
      if (subKey === 'date') {
        // make sure date is inside report period
        if (value < this.props.survey.data.attributes.periodStart
          || value > this.props.survey.data.attributes.periodEnd) {
          return false
        }
      } else if (typeof subKey !== 'undefined') {
        if (isEmpty(value)) {
          return false;
        }
      }
    }

    if (!isValid) {
      //console.log('INVALID', field.fieldKey);
    }

    return isValid;
  }

  // gets a field data object by key
  getFieldDataByKey = (key) => {
    for (let index in this.props.surveyFields.data) {
      let fieldData = this.props.surveyFields.data[index].attributes;
      if (fieldData.fieldKey === key) {
        return fieldData;
      }
    }
    return false;
  };

  showAddressFields = () => {
    this.setState(prevState => {
      return {showAddressFields: !prevState.showAddressFields};
    });
  };

  /**
   *
   * @param key  The field key like traction-employment
   * @param value  The field value like a string or boolean or in the case of traction an array of objects
   * @param index  For identifying the traction sub field element that changed: fieldKey.0
   * @param subKey For identifying the traction sub field element value that changed:  fieldKey.0.date
   */
  handleInputChange = (key, value, index, subKey) => {
    //console.log(`handleInputChange: key="${key}", value="${value}", index="${index}", subKey="${subKey}"`);
    const formData = cloneDeep(this.state.formData);

    const fieldData = this.getFieldDataByKey(key);

    // if this field does not use an index then it is just a simple field
    if (index === undefined) {
      // mark field as touched since this is a change handler, set the value and set the field to invalid
      if (fieldData.fieldType === 'checkbox-multi-select') {
        // get existing values
        let values = [...formData[key].value];
        let x = values.indexOf(value);
        if (x > -1) {
          values.splice(x, 1);
        } else {
          // add new value
          values.push(value);
        }
        value = values;
      }
      // if (fieldData.fieldType === 'company-profile-address-autocomplete') {
      //   formData[key] = {
      //     touched: true,
      //     valid: this.validateAddressFields(formData),
      //     value: value
      //   };
      // } else {
      formData[key] = {
        touched: true,
        valid: this.checkValidity(fieldData, value),
        value: value
      };
      //}

    } else {
      // make sure parent element is defined
      if (formData[key] === undefined) {
        formData[key] = {}
      }
      // make sure parent element is defined
      if (formData[key][index] === undefined) {
        formData[key][index] = {};
      }
      if (formData[key][index][subKey] === undefined) {
        formData[key][index][subKey] = {};
      }
      if (!subKey) {
        throw Error('subKey is required if index is specified');
      }

      //let data = {...formData[key][index][subKey]};
      formData[key][index][subKey].value = value;
      formData[key][index][subKey].touched = true;
      formData[key][index][subKey].valid = this.checkValidity(fieldData, value, subKey);
 //     console.log('INVALID?');
//console.log(key, index, subKey);
//console.log(formData[key][index][subKey].valid);
      //console.log('VALID', formData[key][index][subKey].valid);
      //formData[key][index][subKey] = data;

    }
    //console.log('VALID', formData[key].valid);
    //console.log(formData);
    this.setState({formData: formData}, () => this.validateForm());

  };

  validateForm = () => {
    let formIsValid = true;
    let value = '';
    let field = {};
    const formData = {...this.state.formData};

    for (let fieldKey in formData) {
      try {
        if (Array.isArray(formData[fieldKey])) {
          field = this.getFieldDataByKey(fieldKey);
          if (fieldKey.substr(0, 8) === 'traction' && field.validation.required === true && formData[fieldKey].length === 0) {
            formIsValid = false;
          }

          //validate individual components
          //iterate over all array elements and then all keys
          for (let index in formData[fieldKey]) {
            for (let subKey in formData[fieldKey][index]) {
              value = formData[fieldKey][index][subKey].value;
              formData[fieldKey][index][subKey].valid = this.checkValidity(field, value, subKey);
              //console.log(`CHECKED ${fieldKey}.${index}.${subKey} = "${value}", state=${formData[fieldKey][index][subKey].valid ? 'valid' : 'INVALID'}`);
              formIsValid = formData[fieldKey][index][subKey].valid && formIsValid;
            }
          }
        } else {
          value = formData[fieldKey].value;
          field = this.getFieldDataByKey(fieldKey);
          // if the field we are validating is a company-profile-address autocomplete field then we need to validate
          // all the address fields since autocomplete just fills in these fields.
          if (field.fieldType === 'company-profile-address-autocomplete') {
            formData[fieldKey].valid = this.validateAddressFields(formData, field, value);
          } else {
            formData[fieldKey].valid = this.checkValidity(field, value);
          }
          //console.log(`CHECKED ${fieldKey}="${value}", state=${formData[fieldKey].valid ? 'valid' : 'INVALID'}`);
          formIsValid = formData[fieldKey].valid && formIsValid;
        }
      } catch (e) {
        console.error(e);
        console.error(`validateForm: error with key "${fieldKey}"`);
        formIsValid = false;
      }
    }
    //console.log('FormData', formData);

    // show only invalid fields
    // let invalidData = {};
    // for (let key in formData) {
    //   if (formData[key].valid === false) {
    //     invalidData[key] = formData[key];
    //   }
    // }
    // console.log('Invalid: ', invalidData);

    this.setState({
      formData: formData,
      formIsValid: formIsValid
    });

  };

  validateAddressFields = (formData, field, value) => {
    let valid = true;
    formData['street'].valid = this.checkValidity(this.getFieldDataByKey('street'), formData['street'].value);
    formData['city'].valid = this.checkValidity(this.getFieldDataByKey('city'), formData['city'].value);
    formData['postalCode'].valid = this.checkValidity(this.getFieldDataByKey('postalCode'), formData['postalCode'].value);
    formData['province'].valid = this.checkValidity(this.getFieldDataByKey('province'), formData['province'].value);
    formData['country'].valid = this.checkValidity(this.getFieldDataByKey('country'), formData['country'].value);
    valid = valid && formData['street'].valid;
    valid = valid && formData['city'].valid;
    valid = valid && formData['postalCode'].valid;
    valid = valid && formData['province'].valid;
    valid = valid && formData['country'].valid;
    //console.log('validateAddressFields: ', valid, formData);
    return valid;
  };

  handleSelectAddressAutocomplete = (autocompleteAddress) => {
    //console.log('ADDRESS', autocompleteAddress);

    axios.get( `/places/${autocompleteAddress.id}?sessionToken=${this.props.sessionToken}` )
      .then( response => {
        //console.log(response.data.data.attributes);
        // detailed address
        const address = response.data.data.attributes;
        let formData = cloneDeep(this.state.formData);
        formData = {
          ...formData,
          street: {touched: true, valid: false, value: address.street},
          city: {touched: true, valid: false, value: address.city},
          province: {touched: true, valid: false, value: address.province},
          country: {touched: true, valid: false, value: address.country},
          postalCode: {touched: true, valid: false, value: address.postalCode}
        };
        this.setState({formData: formData}, () => this.validateForm());
      } )
      .catch( error => {
        console.error('Could not load address from places API', error);
      } );
  };

  /**
   * Adds a new traction field to formData state
   *
   * @param fieldKey
   */
  addTractionField = (fieldKey) => {
    const index = this.state.formData[fieldKey].length;
    const formData = {...this.state.formData};

    // make sure indexed field is set
    if (formData[fieldKey][index] === undefined) {
      formData[fieldKey][index] = {};
    }

    // determine type of traction field and set appropriate subKeys to empty property object
    // date fields are set to true since we have a default date of today
    const defaultDate = !isEmpty(this.props.survey.data.attributes.periodEnd) ? moment(this.props.survey.data.attributes.periodEnd).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    switch (fieldKey) {
      case 'traction-employment':
        formData[fieldKey][index]['classification'] = {touched: false, valid: false, value: ''};
        formData[fieldKey][index]['date'] = {touched: true, valid: false, value: defaultDate};
        formData[fieldKey][index]['total'] = {touched: false, valid: false, value: ''};
        break;
      case 'traction-investment':
        formData[fieldKey][index]['amount'] = {touched: false, valid: false, value: ''};
        formData[fieldKey][index]['currency'] = {touched: false, valid: false, value: ''};
        formData[fieldKey][index]['date'] = {touched: true, valid: false, value: defaultDate};
        formData[fieldKey][index]['type'] = {touched: false, valid: false, value: ''};
        break;
      case 'traction-ip':
        formData[fieldKey][index]['country'] = {touched: false, valid: false, value: ''};
        formData[fieldKey][index]['date'] = {touched: true, valid: false, value: defaultDate};
        formData[fieldKey][index]['filingNumber'] = {touched: false, valid: false, value: ''};
        formData[fieldKey][index]['patentType'] = {touched: false, valid: false, value: ''};
        break;
      case 'traction-revenue':
        formData[fieldKey][index]['currency'] = {touched: false, valid: false, value: ''};
        formData[fieldKey][index]['date'] = {touched: true, valid: false, value: defaultDate};
        formData[fieldKey][index]['revenue'] = {touched: false, valid: false, value: ''};
        break;
      case 'traction-stage':
        formData[fieldKey][index]['date'] = {touched: true, valid: false, value: defaultDate};
        formData[fieldKey][index]['stage'] = {touched: false, valid: false, value: ''};
        break;
      default:
        break;
    }

    // validate entire form, this is ok because we only display validation errors if field has been touched
    this.setState({formData: formData}, () => this.validateForm());

  };

  removeTractionField = (fieldKey, index) => {

    const formData = {...this.state.formData};

    // make sure indexed field is set
    if (formData[fieldKey][index] === undefined) {
      throw Error(`fieldKey ${fieldKey} and index ${index} is invalid`);
    }

    formData[fieldKey] = formData[fieldKey].filter((field, fieldIndex) => {
      //console.log('filter', field, fieldIndex, index);
      return fieldIndex !== index;
    });

    // validate entire form, this is ok because we only display validation errors if field has been touched
    this.setState({formData: formData}, () => this.validateForm());

  };

  submitHandler = (e) => {
    if (!this.state.formIsValid || this.props.submitSurvey.loading || this.props.previewMode) {
      e.preventDefault();
      return;
    }
    this.props.handleSurveySubmit(this.state.formData);
  };

  /**
   * Once we have all the form fields loaded we will create a formData object in the state.
   * Each field will have an object created for it to track the value, touched and valid properties.
   *
   * @param props
   * @param state
   * @returns {*}
   */
  static getDerivedStateFromProps(props, state) {
    let field;
    let formData = {};

    // if we have survey data and field data then
    if (!isEmpty(props.surveyFields.data) && !isEmpty(props.survey.data)) {
      // loop over all the properties and replace tags with values
      // yes we are modifying props which is bad but it is the easiest and quickest way to add merge tag functionality
      // without significant refactoring
      Object.keys(props.surveyFields.data).map(key => {
        field = props.surveyFields.data[key].attributes;
        field.fieldLabel = SurveyFields.mergeTags(field.fieldLabel, props);
        field.helpText = SurveyFields.mergeTags(field.helpText, props);
        return null;
      });
    }

    // initialize formData variable in state only if it is uninitialized
    if (props.surveyFields.data.length > 0 && isEmptyObject(state.formData) === true) {
      for (let key in props.surveyFields.data) {
        field = props.surveyFields.data[key].attributes;
        let value = '';
        if (field.fieldType === 'checkbox-multi-select') {
          value = [];
        }

        formData[field.fieldKey] = {
          value: value,
          touched: false,
          valid: false
        };
      }

      // traction fields are arrays so initialize them as such
      if (formData['traction-employment'] !== undefined) {
        formData['traction-employment'] = [];
      }
      if (formData['traction-investment'] !== undefined) {
        formData['traction-investment'] = [];
      }
      if (formData['traction-ip'] !== undefined) {
        formData['traction-ip'] = [];
      }
      if (formData['traction-revenue'] !== undefined) {
        formData['traction-revenue'] = [];
      }
      if (formData['traction-stage'] !== undefined) {
        formData['traction-stage'] = [];
      }

      // load test data into form, this overwrites all the initializations above

      //formData = updateObject(formData, TestFormData);



      return {formData: formData};

    // populate the survey fields company name component with the company name from survey api call.
    // if we have survey fields data and we have already populated form data with data...
    // and if we have a company name (from survey)
    // and the company name is different than the one we already set
    } else if (props.surveyFields.data.length > 0 &&
      isEmptyObject(state.formData) === false &&
      isEmpty(props.company) === false &&
      isEmpty(state.formData['company-profile-name']) === false &&
      props.company.name !== state.formData['company-profile-name'].value) {
      formData = {...state.formData};
      formData['company-profile-name'].value = props.company.name;
      return {formData: formData};
    }

    return null;
  }

  static mergeTags(input, props) {
    if (input === null) {
      return input;
    }
    if (typeof props.survey.data === 'undefined') {
      return input;
    }
    if (typeof props.survey.data.attributes.periodStart === 'undefined') {
      return input;
    }
    if (typeof props.survey.data.attributes.periodEnd === 'undefined') {
      return input;
    }

    const merges = [
      {
        tag: 'SURVEY_PERIOD_START',
        replacement: moment(props.survey.data.attributes.periodStart).format('ll')
      },
      {
        tag: 'SURVEY_PERIOD_END',
        replacement: moment(props.survey.data.attributes.periodEnd).format('ll')
      }
    ];

    merges.map(merge => {
      const re =  new RegExp(`\\[${merge.tag}\\]`,'g');
      input = input.replace(re, merge.replacement);
      return null;
    });
    return input;
  }

  render () {
    let fields = '';
    let button = '';
    if (this.props.surveyFields.loading === true) {
      button = '';
      fields = <Spinner dark/>;
    } else if (this.props.surveyFields.error) {
      button = '';
      fields = <Alert color="danger" style={{textAlign: 'center'}}>Error loading survey fields</Alert>;
    } else if (this.props.surveyFields.data.length > 0) {
      //console.log('FORM DATA', this.state.formData);
      button = (
        <div>
          {this.props.previewMode ? <Alert color="warning">You cannot submit this survey because it is in preview mode.</Alert> : null}
          {this.props.previewMode ? this.state.formIsValid ? <Alert color="success"><strong>(Preview Mode)</strong> Form is valid.</Alert> : <Alert color="danger"><strong>(Preview Mode)</strong> Form is invalid.</Alert> : null}
          <Button color="primary"
                  className={!this.state.formIsValid || this.props.submitSurvey.loading || this.props.previewMode ? 'disabled' : ''}
                  id="submit"
                  onClick={this.submitHandler}
                  size="lg"
          >Submit</Button>
          {
            !this.state.formIsValid || this.props.submitSurvey.loading || this.props.previewMode
              ? <UncontrolledTooltip placement="top" trigger="click" target="submit">
                  Uh, oh! It looks likes some fields still need to be completed.
                </UncontrolledTooltip>
              : null
          }
          {this.props.submitSurvey.loading ? <Spinner dark/> : '' }
          {this.props.submitSurvey.error ? <Alert color="danger" style={{textAlign: 'center'}}>
            There was an error submitting your form.
          </Alert> : ''}
        </div>
      );

      fields = Object.keys(this.props.surveyFields.data)
        .map(igKey => {
          const fieldData = this.props.surveyFields.data[igKey];
          // if preQuestion field is set then we need to wrap the component below in side it
          let field = '';
          switch (fieldData.attributes.dataType) {
            case 'cdmn':
              switch (fieldData.attributes.fieldType) {
                case 'connectingAccount':
                  field = <Select changed={this.handleInputChange}
                                  field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  key={igKey}
                                  options={this.props.lists.cdmnHubs}/>;
                  break;
                case 'firstChoiceCountry':
                /* falls through */
                case 'secondChoiceCountry':
                /* falls through */
                case 'thirdChoiceCountry':
                  field = <Select changed={this.handleInputChange}
                                  field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  key={igKey}
                                  options={this.props.lists.countries}/>;
                  break;
                default:
                  field = <TextInput changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     key={igKey}/>;
              }
              break;
            case 'company-meta':
              switch (fieldData.attributes.fieldType) {
                case 'focus':
                  //console.log(this.state.formData['industry']);
                  //console.log(fieldData.attributes);
                  const isIndustrySet = !isEmpty(this.state.formData['industry'].value);
                  const focusOptions = isIndustrySet ? this.props.lists.focus[this.state.formData['industry'].value] : {};

                  field = <Select field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  options={focusOptions}
                                  readOnly={!isIndustrySet}
                                  key={igKey}
                                  changed={this.handleInputChange}/>;
                  break;
                case 'industry':
                  field = <Select field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  options={this.props.lists.industry}
                                  key={igKey}
                                  changed={this.handleInputChange}/>;
                  break;
                case 'offering':
                  field = <Select changed={this.handleInputChange}
                                  field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  key={igKey}
                                  options={this.props.lists.offering}/>;
                  break;
                case 'numberFounders':
                  /* falls through */
                case 'numberFoundersYouth':
                  /* falls through */
                case 'numberFoundersFirstVenture':
                  /* falls through */
                case 'numberFoundersFemale':
                  field = <TextInput changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     inputType="number"
                                     key={igKey}/>;
                  break;
                default:
                  field = <TextInput changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     key={igKey}/>;
                  break;
                // default:
                //   field = '';
              }
              break;
            case 'company-profile':
              const addressKeys = ['street', 'street2', 'city', 'province', 'country', 'postalCode'];
              if (this.state.showAddressFields === true || !addressKeys.includes(fieldData.attributes.fieldKey)) {
                switch (fieldData.attributes.fieldType) {
                  case 'bio':
                    field = <TextInput changed={this.handleInputChange}
                                       field={fieldData.attributes}
                                       fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                       inputType="textarea"
                                       key={igKey}/>;
                    break;
                  case 'incDate':
                    field = <DatePickerCustomFormGroup
                      field={fieldData.attributes}
                      id={fieldData.attributes.fieldKey}
                      onChange={(date) => this.handleInputChange(fieldData.attributes.fieldKey, moment(date).format('YYYY-MM-DD'))}
                      key={igKey}
                      selected={this.state.formData[fieldData.attributes.fieldKey].value ? moment(this.state.formData[fieldData.attributes.fieldKey].value).toDate() : null}
                      />;
                    break;
                  default:
                    field = <TextInput changed={this.handleInputChange}
                                       field={fieldData.attributes}
                                       fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                       inputType="text"
                                       key={igKey}/>;
                }

              }
              break;
            case 'company-profile-address-autocomplete':
              field = <AddressAutocomplete changed={this.handleInputChange}
                                           field={fieldData.attributes}
                                           fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                           handleSetTypeaheadRef={this.props.handleCompanyNameSetTypeaheadRef}
                                           key={igKey}
                                           selected={this.handleSelectAddressAutocomplete}
                                           sessionToken={this.props.sessionToken}
                                           showAddressFields={this.showAddressFields}/>;
              break;
            case 'company-profile-name':
              if (this.props.company === null || this.props.company.name === '') {
                field = <CompanyName changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     handleSetTypeaheadRef={this.props.handleCompanyNameSetTypeaheadRef}
                                     key={igKey}
                                     selected={(value) => this.props.handleSelectCompany(value, () => {
                                       const formData = {...this.state.formData};
                                       formData['company-profile-name'].value = '';
                                       this.setState({formData: formData}, () => this.validateForm());
                                     })}/>;
              } else {
                field = <FormGroup key={igKey}>
                          <Label for="survey-field-company-profile-name">{fieldData.attributes.fieldLabel} {fieldData.attributes.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
                          <Input type="text"
                                 value={this.state.formData[fieldData.attributes.fieldKey].value} readOnly/>
                        </FormGroup>;
              }
              break;
            case 'heading':
              field = <Heading field={fieldData.attributes} key={igKey} />;
              break;
            case 'passportProgram':
              switch (fieldData.attributes.fieldType) {
                case 'endDate':
                /* falls through */
                case 'startDate':
                  field = <DatePickerCustomFormGroup
                            field={fieldData.attributes}
                            id={fieldData.attributes.fieldKey}
                            onChange={(date) => this.handleInputChange(fieldData.attributes.fieldKey, moment(date).format('YYYY-MM-DD'))}
                            key={igKey}
                            selected={this.state.formData[fieldData.attributes.fieldKey].value ? moment(this.state.formData[fieldData.attributes.fieldKey].value).toDate() : null}/>;
                  break;
                case 'destinationHub':
                /* falls through */
                case 'homeHub':
                  field = <Select changed={this.handleInputChange}
                                  field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  key={igKey}
                                  options={this.props.lists.cdmnHubs}/>;
                  break;
                default:
                  field = <TextInput changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     key={igKey}/>;
                  break;
                // default:
                //   field = '';
              }
              break;
            case 'person-profile':
              switch (fieldData.attributes.fieldType) {
                case 'role':
                  field = <Select field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  options={this.props.lists.role}
                                  key={igKey}
                                  changed={this.handleInputChange}/>;
                  break;
                default:
                  field = <TextInput key={igKey}
                                     inputType="text"
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     changed={this.handleInputChange}/>;
              }
              break;
            case 'survey-data-only':
              switch (fieldData.attributes.fieldType) {
                // single checkbox, like for agreeing to terms
                case 'attachment':
                  field = <Attachment changed={this.handleInputChange}
                                      field={fieldData.attributes}
                                      fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                      key={igKey}/>;
                  break;
                case 'checkbox':
                  field = <Checkbox changed={this.handleInputChange}
                                    field={fieldData.attributes}
                                    fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                    key={igKey}/>;
                  break;
                case 'checkbox-multi-select':
                  field = <CheckboxMultiSelect changed={this.handleInputChange}
                                               field={fieldData.attributes}
                                               fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                               key={igKey}/>;
                  break;
                case 'rating-radio':
                  fieldData.attributes.options = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
                  field = <RatingRadio changed={this.handleInputChange}
                                       field={fieldData.attributes}
                                       fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                       key={igKey}/>;
                  break;
                case 'select-dropdown':
                  field = <Select changed={this.handleInputChange}
                                  field={fieldData.attributes}
                                  fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                  key={igKey}/>;
                  break;
                case 'select-radio':
                  field = <SelectRadio changed={this.handleInputChange}
                                       field={fieldData.attributes}
                                       fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                       key={igKey}/>;
                  break;
                case 'company-name':
                  field = <CompanyName changed={this.handleInputChange}
                                       field={fieldData.attributes}
                                       fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                       handleSetTypeaheadRef={this.props.handleCompanyNameSetTypeaheadRef}
                                       key={igKey}
                                       selected={(company) => {
                                         const formData = {...this.state.formData};
                                         // formData['communitechCompanyId'] = {value: ''};
                                         // formData['communitechCompanyId'].value = company.communitechCompanyId;
                                         formData[fieldData.attributes.fieldKey] = {value: ''};
                                         formData[fieldData.attributes.fieldKey].value = company.name;
                                         this.setState({formData: formData}, () => this.validateForm());
                                       }}
                                       />;
                  break;
                case 'textarea':
                  field = <TextInputWysiwyg changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     key={igKey}/>;
                  break;
                  /* falls through */
                case 'text':
                  /* falls through */
                case 'integer':
                  /* falls through */
                default:
                  if (fieldData.attributes.fieldType === 'integer') {
                    fieldData.attributes.fieldType = 'number';
                  }
                  field = <TextInput changed={this.handleInputChange}
                                     field={fieldData.attributes}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     key={igKey}/>;
              }
              break;
            case 'tag':
              field = <Checkbox changed={this.handleInputChange}
                                field={fieldData.attributes}
                                fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                key={igKey}/>;
              break;
            case 'traction-employment':
              field = <TractionGroup addField={this.addTractionField}
                                     buttonText="Add part time or full time employees"
                                     changed={this.handleInputChange}
                                     description={fieldData.attributes.helpText}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     fieldKey={fieldData.attributes.fieldKey}
                                     key={igKey}
                                     label={fieldData.attributes.fieldLabel}
                                     removeField={this.removeTractionField}
                                     required={fieldData.attributes.validation.required}
                                     type="employment"
                                     types={this.props.lists.tractionTypesEmployment} />;
              break;
            case 'traction-investment':
              field = <TractionGroup addField={this.addTractionField}
                                     buttonText="Add investment"
                                     changed={this.handleInputChange}
                                     description={fieldData.attributes.helpText}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     fieldKey={fieldData.attributes.fieldKey}
                                     key={igKey}
                                     label={fieldData.attributes.fieldLabel}
                                     removeField={this.removeTractionField}
                                     required={fieldData.attributes.validation.required}
                                     type="investment"
                                     types={this.props.lists.tractionTypesInvestment}/>;
              break;
            case 'traction-ip':
              field = <TractionGroup addField={this.addTractionField}
                                     buttonText="Add IP"
                                     changed={this.handleInputChange}
                                     description={fieldData.attributes.helpText}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     fieldKey={fieldData.attributes.fieldKey}
                                     key={igKey}
                                     label={fieldData.attributes.fieldLabel}
                                     removeField={this.removeTractionField}
                                     required={fieldData.attributes.validation.required}
                                     type="ip"
                                     types={this.props.lists.tractionTypesIp}/>;
              break;
            case 'traction-revenue':
              field = <TractionGroup addField={this.addTractionField}
                                     buttonText="Add revenue"
                                     changed={this.handleInputChange}
                                     description={fieldData.attributes.helpText}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     fieldKey={fieldData.attributes.fieldKey}
                                     key={igKey}
                                     label={fieldData.attributes.fieldLabel}
                                     removeField={this.removeTractionField}
                                     required={fieldData.attributes.validation.required}
                                     type="revenue"
                                     types={{}} />;
              break;
            case 'traction-stage':
              field = <TractionGroup addField={this.addTractionField}
                                     buttonText="Add stage"
                                     changed={this.handleInputChange}
                                     description={fieldData.attributes.helpText}
                                     fieldData={this.state.formData[fieldData.attributes.fieldKey]}
                                     fieldKey={fieldData.attributes.fieldKey}
                                     key={igKey}
                                     label={fieldData.attributes.fieldLabel}
                                     removeField={this.removeTractionField}
                                     required={fieldData.attributes.validation.required}
                                     type="stage"
                                     types={this.props.lists.tractionTypesStage}/>;
              break;
            default:
          }

          if (fieldData.attributes.preQuestionLabel) {
            field = <PreQuestion field={fieldData.attributes}
                                 key={igKey}
                                 validateForm={this.validateForm}>{field}</PreQuestion>
          }

          return field;
        });
    } else {
      button = '';
      fields = '';
    }

    return (
      <Form className="survey-fields" noValidate autoComplete="off">
        {fields}
        {button}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    submitSurvey: state.submitSurvey
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

//export default SurveyFields;
export default connect(mapStateToProps, mapDispatchToProps)(SurveyFields);

import * as actionTypes from './actionTypes';
import axios from '../../axios';
import {cloneDeep} from 'lodash';

export const fetchSurveyStart = () => {
  return {
    type: actionTypes.FETCH_SURVEY_START
  };
};

export const fetchSurvey = (surveyHash, previewMode) => {
  return dispatch => {
    dispatch(fetchSurveyStart());
    axios.get( '/survey/' + surveyHash + '?preview=' + previewMode)
      .then( response => {
        dispatch(fetchSurveySuccess(response.data));
      } )
      .catch( error => {
        dispatch(fetchSurveyFailure(error));
      } );
  };
};

export const fetchSurveySuccess = ( data ) => {
  return {
    type: actionTypes.FETCH_SURVEY_SUCCESS,
    data: data
  };
};

export const fetchSurveyFailure = ( error ) => {
  return {
    type: actionTypes.FETCH_SURVEY_FAILURE,
    error: error
  };
};


export const fetchSurveyFieldsStart = () => {
  return {
    type: actionTypes.FETCH_SURVEY_FIELDS_START
  };
};

export const fetchSurveyFields = (surveyHash, previewMode) => {
  return dispatch => {
    dispatch(fetchSurveyFieldsStart());
    axios.get(`/survey/${surveyHash}/fields?preview=${previewMode}`)
      .then(response => {
        // inject validation fields into data
        const responseData = cloneDeep(response.data);
        for (let index in responseData.data) {
          responseData.data[index].attributes = {
            ...responseData.data[index].attributes,
            valid: false,
            touched: false,
            value: null
          }
        }
        // const responseData = response.data;
        dispatch(fetchSurveyFieldsSuccess(responseData));
      })
      .catch( error => {
        dispatch(fetchSurveyFieldsFailure(error))
      });
  };
};

export const fetchSurveyFieldsSuccess = ( data ) => {
  return {
    type: actionTypes.FETCH_SURVEY_FIELDS_SUCCESS,
    data: data
  };
};

export const fetchSurveyFieldsFailure = ( error ) => {
  return {
    type: actionTypes.FETCH_SURVEY_FIELDS_FAILURE,
    error: error
  };
};

export const submitSurveyInit = (surveyHash, submission) => {
  return dispatch => {
    //console.log('submitSurveyInit');
    dispatch(submitSurveyStart());
    axios.post(`/survey/${surveyHash}/responses`, submission)
      .then(response => {
        dispatch(submitSurveySuccess(response.data.data.id))
      })
      .catch( error => {
        dispatch(submitSurveyFailure(error))
      });
  };
};

export const submitSurveyFailure = (error) => {
  //console.log('submitSurveyFailure');
  return {
    type: actionTypes.SUBMIT_SURVEY_FAILURE,
    error: error
  };
};

export const submitSurveyStart = () => {
  // console.log('submitSurveyStart');
  return {
    type: actionTypes.SUBMIT_SURVEY_START
  };
};

export const submitSurveySuccess = (id) => {
  //console.log('submitSurveySuccess');
  return {
    type: actionTypes.SUBMIT_SURVEY_SUCCESS,
    //responseId: id,
    submitted: false
  };
};

export const validateSurveyField = (field) => {
  return {
    type: actionTypes.VALIDATE_SURVEY_FIELD,
    field: field
  };
};
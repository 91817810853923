import React, { Component } from 'react';
import {FormGroup, Button, Label, FormText} from "reactstrap";
import Employment from './Employment/Employment';
import Investment from './Investment/Investment';
import Ip from './Ip/Ip';
import Revenue from './Revenue/Revenue';
import Stage from './Stage/Stage';
import PropTypes from 'prop-types';
import { FaAsterisk } from 'react-icons/fa';

class TractionGroup extends Component {
  state = {
    fields: [],
    formData: []
  };

  addField = (index) => {

    index = parseInt(index);
    //console.log('ADD FIELD', this.props.fieldData[index]);
    let component = null;
    switch (this.props.type) {
      case 'employment':
        component = <Employment changed={this.props.changed}
                                classification={this.props.fieldData[index].classification}
                                date={this.props.fieldData[index].date}
                                fieldKey={this.props.fieldKey}
                                index={index}
                                key={index}
                                removeField={this.props.removeField}
                                total={this.props.fieldData[index].total}
                                types={this.props.types}/>;
        break;
      case 'investment':
        component = <Investment amount={this.props.fieldData[index].amount}
                                changed={this.props.changed}
                                currency={this.props.fieldData[index].currency}
                                date={this.props.fieldData[index].date}
                                fieldKey={this.props.fieldKey}
                                index={index}
                                key={index}
                                removeField={this.props.removeField}
                                type={this.props.fieldData[index].type}
                                types={this.props.types}/>;
        break;
      case 'ip':
        component = <Ip changed={this.props.changed}
                        country={this.props.fieldData[index].country}
                        date={this.props.fieldData[index].date}
                        filingNumber={this.props.fieldData[index].filingNumber}
                        fieldKey={this.props.fieldKey}
                        index={index}
                        key={index}
                        patentType={this.props.fieldData[index].patentType}
                        removeField={this.props.removeField}
                        types={this.props.types}/>;
        break;
      case 'revenue':
        component = <Revenue changed={this.props.changed}
                             currency={this.props.fieldData[index].currency}
                             date={this.props.fieldData[index].date}
                             fieldKey={this.props.fieldKey}
                             index={index}
                             key={index}
                             revenue={this.props.fieldData[index].revenue}
                             removeField={this.props.removeField}/>;
        break;
      case 'stage':
        component = <Stage changed={this.props.changed}
                           date={this.props.fieldData[index].date}
                           fieldKey={this.props.fieldKey}
                           index={index}
                           key={index}
                           removeField={this.props.removeField}
                           stage={this.props.fieldData[index].stage}
                           types={this.props.types}/>;
        break;
      default:
        throw Error(`Invalid prop type: ${this.props.type}`);
    }
    //const fields = [...this.state.fields, component];

    //this.setState( { fields: fields } );
    return component;
  };

  render() {
    let fields = [];
    for (let index in this.props.fieldData) {
      fields.push(this.addField(index));
    }

    const stageHelp = this.props.type === 'stage' && fields.length > 0 ? (
      <div>
        <strong>Idea:</strong> identifying problem/solution, prototype, IP and financing strategy, founding team.<br/>
        <strong>Discovery:</strong> MVP, market testing, funds for development and market acceptance.<br/>
        <strong>Validation:</strong> payment ready, early sales, funds to scale.<br/>
        <strong>Efficiency:</strong> manufacturing and supply chain, repeatable sales model, proven profitable business model roadmap.<br/>
        <strong>Scale:</strong> becoming a (sustainable) business.
      </div>
    ) : '';

    return (
      <FormGroup>
        <Label>{this.props.label} {this.props.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
        <FormText color="muted">{this.props.description}</FormText>
        {fields.map((field) => field)}
        {stageHelp}
        <Button color="secondary" outline size="sm" onClick={() => this.props.addField(this.props.fieldKey)}>{this.props.buttonText}</Button>
      </FormGroup>
    );
  }
}

TractionGroup.propTypes = {
  addField: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  changed: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  fieldData: PropTypes.array.isRequired,
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  removeField: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  types: PropTypes.object.isRequired
};

export default TractionGroup;
import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchListsStart = () => {
  return {
    type: actionTypes.FETCH_LISTS_START
  };
};

export const fetchLists = (surveyHash) => {
  return dispatch => {
    dispatch(fetchListsStart());
    axios.get( '/lists/' + surveyHash )
      .then( response => {
        dispatch(fetchListsSuccess(response.data));
      } )
      .catch( error => {
        dispatch(fetchListsFailure(error));
      } );
  };
};

export const fetchListsSuccess = ( data ) => {
  return {
    type: actionTypes.FETCH_LISTS_SUCCESS,
    data: data
  };
};

export const fetchListsFailure = ( error ) => {
  return {
    type: actionTypes.FETCH_LISTS_FAILURE,
    error: error
  };
};

import React, {Component} from 'react';
import {FormGroup, Button, Row, Col, Input, Label, FormText} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.min.css';
import PropTypes from "prop-types";
import moment from "moment";
import DatePickerCustom from '../../../DatePickerCustom/DatePickerCustom';

class Stage extends Component {

  render() {
    const idPrefix = 'survey-field-' + this.props.fieldKey;
    const options = Object.keys( this.props.types )
      .map( igKey => {
        return <option key={igKey} value={igKey}>{this.props.types[igKey]}</option>
      });

    return (
      <FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for={`${idPrefix}-date-${this.props.index}`}>Date *</Label>
              <DatePickerCustom date={this.props.date}
                                id={`${idPrefix}-date-${this.props.index}`}
                                onChange={(date) => this.props.changed(this.props.fieldKey, moment(date).format('YYYY-MM-DD'), this.props.index, 'date')}
                                selected={moment(this.props.date.value).toDate()}/>
              <FormText color="muted">Date for stage Entry (select today)</FormText>
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup className={this.props.stage.touched && !this.props.stage.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-stage-${this.props.index}`}>Stage *</Label>
              <Input id={`${idPrefix}-stage-${this.props.index}`}
                     invalid={this.props.stage.touched && !this.props.stage.valid}
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'stage')}
                     placeholder="Currency"
                     type="select"
                     value={this.props.stage.value}
                     valid={this.props.stage.touched && this.props.stage.valid} >
                <option value="" disabled>Select a stage</option>
                {options}
              </Input>
              <FormText color="muted">The current stage of your company.</FormText>
            </FormGroup>
          </Col>
          <Col md={1} className="text-center">
            <Button close style={{float: 'none'}} title="Remove field" className="h-100" onClick={() => this.props.removeField(this.props.fieldKey, this.props.index)}/>
          </Col>

        </Row>
      </FormGroup>
    );
  }
}

Stage.propTypes = {
  changed: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fieldKey: PropTypes.string.isRequired,
  removeField: PropTypes.func.isRequired,
  stage: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired
};

export default Stage;
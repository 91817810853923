import * as actionTypes from "./actionTypes";
import axios from "../../axios";

export const selectCompanyStart = () => {
  return {
    type: actionTypes.SELECT_COMPANY_START
  };
};

export const selectCompanySuccess = (id) => {
  return {
    type: actionTypes.SELECT_COMPANY_SUCCESS,
    responseId: id
  };
};

export const selectCompanyFailure = (error) => {
  return {
    type: actionTypes.SELECT_COMPANY_FAILURE,
    error: error
  };
};

export const selectCompanySubmit = (surveyHash, companyId) => {
  return dispatch => {
    dispatch(selectCompanyStart());
    const data = {
      data: {
        attributes: {
          action: 'inviteCompany',
          companyId: companyId
        }
      }
    };
    axios.post(`/survey/${surveyHash}/actions`, data)
      .then(response => {
        dispatch(selectCompanySuccess(response.data.data.id))
      })
      .catch( error => {
        dispatch(selectCompanyFailure(error))
      });
  };
};
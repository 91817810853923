import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { cloneDeep } from 'lodash';

const initialState = {
  lists: {
    loading: false,
    error: null,
    data: {
      attributes: {
        industry: {},
        focus: {},
        offering: {},
        program: {},
        fundingBucket: {},
        scaleUpColour: {},
        tractionTypesEmployment: {},
        tractionTypesInvestment: {},
        tractionTypesIp: {},
        tractionTypesStage: {}
      }
    }
  },
  milestones: {
    loading: false,
    error: null,
    data: []
  },
  survey: {
    loading: false,
    error: null,
    data: {
      id: null,
      type: '',
      attributes: {
        branding: '',
        company: {
          name: ''
        },
        expires: '',
        name: '',
        periodStart: '',
        periodEnd: '',
        message: null,
        thankYouMessage: null,
      }
    }
  },
  submitSurvey: {
    loading: false,
    error: null,
    submitted: false,
    responseId: null,
    data: []
  },
  surveyFields: {
    loading: false,
    error: null,
    data: []
  },
  selectCompany: {
    loading: false,
    selected: false,
    error: null,
    data: []
  }
};


const fetchListsStart = ( state, action ) => {
  const lists = updateObject(state.lists, {
    loading: true
  });
  return updateObject( state, {
    lists: lists
  } );
};

const fetchListsSuccess = ( state, action ) => {
  const lists = updateObject(action.data, {
    loading: false
  });
  return updateObject( state, {
    lists: lists
  } );
};

const fetchListsFailure = ( state, action ) => {

  const lists = updateObject(state.lists, {
    loading: false,
    error: action.error
  });

  return updateObject( state, {
    lists: lists
  } );
};


const fetchMilestonesStart = ( state, action ) => {
  const milestones = updateObject(state.milestones, {
    loading: true
  });
  return updateObject( state, {
    milestones: milestones
  } );
};

const fetchMilestonesSuccess = ( state, action ) => {
  const milestones = updateObject(action.data, {
    loading: false
  });
  return updateObject( state, {
    milestones: milestones
  } );
};

const fetchMilestonesFailure = ( state, action ) => {

  const milestones = updateObject(state.milestones, {
    loading: false,
    error: action.error
  });

  return updateObject( state, {
    milestones: milestones
  } );
};


// Update the state survey.loading = true
const fetchSurveyStart = ( state, action ) => {
  const survey = updateObject(state.survey, {
    loading: true
  });
  return updateObject( state, {
    survey: survey
  } );
};

// Update the state state.survey.loading = true and the state.survey object
const fetchSurveySuccess = ( state, action ) => {
  const survey = updateObject(action.data, {
    loading: false
  });
  return updateObject( state, {
    survey: survey
  } );
};

// Update the state.survey.loading and state.survey.error
const fetchSurveyFailure = ( state, action ) => {

  const survey = updateObject(state.survey, {
    loading: false,
    error: action.error
  });

  return updateObject( state, {
    survey: survey
  } );
};


const fetchSurveyFieldsStart = ( state, action ) => {
  const surveyFields = updateObject(state.surveyFields, {
    loading: true
  });
  return updateObject( state, {
    surveyFields: surveyFields
  } );
};

const fetchSurveyFieldsSuccess = ( state, action ) => {
  const surveyFields = updateObject(action.data, {
    loading: false
  });
  return updateObject( state, {
    surveyFields: surveyFields
  } );
};


const fetchSurveyFieldsFailure = ( state, action ) => {

  const surveyFields = updateObject(state.surveyFields, {
    loading: false,
    error: action.error
  });

  return updateObject( state, {
    surveyFields: surveyFields
  } );
};

const selectCompanyInit = ( state, action ) => {
  const selectCompany = updateObject(state.selectCompany, {
    selected: false
  });
  return updateObject( state, {
    selectCompany: selectCompany
  });
};

const selectCompanyStart = ( state, action ) => {
  const selectCompany = updateObject(state.selectCompany, {
    loading: true
  });
  return updateObject( state, {
    selectCompany: selectCompany
  });
};

const selectCompanySuccess = ( state, action ) => {
  const selectCompany = updateObject(state.selectCompany, {
    selected: true,
    loading: false,
    company: action.company
  });
  return updateObject( state, {
    selectCompany: selectCompany
  });
};

const selectCompanyFailure = ( state, action ) => {
  const selectCompany = updateObject(state.selectCompany, {
    loading: false,
    error: action.error
  });
  return updateObject( state, {
    selectCompany: selectCompany
  });
};

const submitSurveyFailure = ( state, action ) => {
  //console.log('reducer.submitSurveyFailure');
  const submitSurvey = updateObject(state.submitSurvey, {
    loading: false,
    error: action.error
  });
  return updateObject( state, {
    submitSurvey: submitSurvey
  } );
};

const submitSurveyStart = ( state, action ) => {
  //console.log('reducer.submitSurveyStart');
  const submitSurvey = updateObject(state.submitSurvey, {
    loading: true
  });
  return updateObject( state, {
    submitSurvey: submitSurvey
  } );
};

const submitSurveySuccess = ( state, action ) => {
  //console.log('reducer.submitSurveySuccess', action);
  const submitSurvey = updateObject(action.data, {
    loading: false,
    submitted: true,
    //responseId: action.data.responseId
    // should be a survey reponse id
  });
  return updateObject( state, {
    submitSurvey: submitSurvey
  });
};


const validateSurveyField = ( state, action ) => {
  // merge new field properties (in action) into existing state
  //console.log('REDUCER.action', action);
  //console.log('REDUCER.state', state.surveyFields);
  // action.field should be an element of state.surveyFields.data

  const surveyFields = cloneDeep(state.surveyFields);

  //let fieldIndex = null;
  for (let index in surveyFields.data) {
    if (action.field.id === surveyFields.data[index].id) {
      //fieldIndex = index;
      surveyFields.data[index].attributes = action.field;
      break;
    }
  }
  //console.log('REDUCER.state (original', state.surveyFields);
  //console.log('REDUCER.state (new)', surveyFields);

  return updateObject( state, {
    surveyFields: surveyFields
  } );
};



const reducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LISTS_START: return fetchListsStart(state, action);
    case actionTypes.FETCH_LISTS_SUCCESS: return fetchListsSuccess(state, action);
    case actionTypes.FETCH_LISTS_FAILURE: return fetchListsFailure(state, action);
    case actionTypes.FETCH_MILESTONES_START: return fetchMilestonesStart(state, action);
    case actionTypes.FETCH_MILESTONES_SUCCESS: return fetchMilestonesSuccess(state, action);
    case actionTypes.FETCH_MILESTONES_FAILURE: return fetchMilestonesFailure(state, action);
    case actionTypes.FETCH_SURVEY_START: return fetchSurveyStart(state, action);
    case actionTypes.FETCH_SURVEY_SUCCESS: return fetchSurveySuccess(state, action);
    case actionTypes.FETCH_SURVEY_FAILURE: return fetchSurveyFailure(state, action);
    case actionTypes.FETCH_SURVEY_FIELDS_START: return fetchSurveyFieldsStart(state, action);
    case actionTypes.FETCH_SURVEY_FIELDS_SUCCESS: return fetchSurveyFieldsSuccess(state, action);
    case actionTypes.FETCH_SURVEY_FIELDS_FAILURE: return fetchSurveyFieldsFailure(state, action);
    case actionTypes.SUBMIT_SURVEY_START: return submitSurveyStart( state, action );
    case actionTypes.SUBMIT_SURVEY_SUCCESS: return submitSurveySuccess( state, action )
    case actionTypes.SUBMIT_SURVEY_FAILURE: return submitSurveyFailure( state, action );
    case actionTypes.SELECT_COMPANY_INIT: return selectCompanyInit( state, action );
    case actionTypes.SELECT_COMPANY_START: return selectCompanyStart( state, action );
    case actionTypes.SELECT_COMPANY_SUCCESS: return selectCompanySuccess( state, action );
    case actionTypes.SELECT_COMPANY_FAILURE: return selectCompanyFailure( state, action );
    case actionTypes.VALIDATE_SURVEY_FIELD: return validateSurveyField(state, action);
    default:
      return state;
  }
};

export default reducers;

export const FETCH_LISTS_START = 'FETCH_LISTS_START';
export const FETCH_LISTS_SUCCESS = 'FETCH_LISTS_SUCCESS';
export const FETCH_LISTS_FAILURE = 'FETCH_LISTS_FAILURE';

export const FETCH_MILESTONES_START = 'FETCH_MILESTONES_START';
export const FETCH_MILESTONES_SUCCESS = 'FETCH_MILESTONES_SUCCESS';
export const FETCH_MILESTONES_FAILURE = 'FETCH_MILESTONES_FAILURE';

export const FETCH_SURVEY_FIELDS_START = 'FETCH_SURVEY_FIELDS_START';
export const FETCH_SURVEY_FIELDS_SUCCESS = 'FETCH_SURVEY_FIELDS_SUCCESS';
export const FETCH_SURVEY_FIELDS_FAILURE = 'FETCH_SURVEY_FIELDS_FAILURE';

export const FETCH_SURVEY_START = 'FETCH_SURVEY_START';
export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
export const FETCH_SURVEY_FAILURE = 'FETCH_SURVEY_FAILURE';

export const SELECT_COMPANY_INIT = 'SELECT_COMPANY_INIT';
export const SELECT_COMPANY_START = 'SELECT_COMPANY_START';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';
export const SELECT_COMPANY_FAILURE = 'SELECT_COMPANY_FAILURE';

export const SUBMIT_SURVEY_START = 'SUBMIT_SURVEY_START';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_FAILURE = 'SUBMIT_SURVEY_FAILURE';

export const VALIDATE_SURVEY_FIELD = 'VALIDATE_SURVEY_FIELDS';
import React, { Component } from 'react';
import axios from "../../../axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import CompanyNameItem from './CompanyNameItem/CompanyNameItem';
import { FormText, FormGroup, Label } from 'reactstrap';
import { FaAsterisk } from 'react-icons/fa';

class CompanyName extends Component {

  state = {
    isLoading: false,
    options: [],
  };

  componentDidUpdate() {
    this.props.handleSetTypeaheadRef(this.typeahead.getInstance());
  }

  render() {
    // const changeHandler = this.props.changed ? (text, event) => {
    //   return this.props.changed(event, this.props.field.id)
    // } : () => {};

    const selectHandler = this.props.selected ? (selected) => {
      if (selected.length === 0) {
        return () => {};
      }
      // call the selected callback with the company object
      return this.props.selected(selected[0])
    } : () => {};

    let color = 'inherit';
    if (this.props.field.touched && this.props.field.valid) {
      color = '#28a745';
    }
    if (this.props.field.touched && !this.props.field.valid) {
      color = '#dc3545';
    }

    return (
      <FormGroup className={this.props.fieldData.touched && !this.props.fieldData.valid ? 'alert alert-danger' : ''}>
        <Label style={{color: color}}for="survey-field-company-profile-name">{this.props.field.fieldLabel} {this.props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
        <AsyncTypeahead
          {...this.state}
          id="survey-field-company-profile-name"
          labelKey="name"
          minLength={3}
          onChange={selectHandler}
          isInvalid={this.props.fieldData.touched && !this.props.fieldData.valid}
          isLoading={this.state.isLoading}
          isValid={this.props.fieldData.touched && this.props.fieldData.valid}
          onInputChange={(value) => this.props.changed(this.props.field.fieldKey, value)}
          onSearch={this.handleSearch}
          placeholder="Enter your company name"
          ref={(ref) => this.typeahead = ref}
          renderMenuItemChildren={(option, props) => (
            <CompanyNameItem key={option.id} item={option}/>
          )}
          defaultInputValue={this.props.fieldData.value}
        />
        <FormText color="muted">Please enter your company name OR the company name you have in mind OR first initial, last initial followed by acme (eg. JD Acme 1). Select your company if you see it in the list.</FormText>
      </FormGroup>
    );
  }

  handleSearch = (query) => {
    this.setState({isLoading: true});
    axios.get( `/companies?query=${query}` )
      .then( response => {
        const items = response.data.data.attributes;
        this.setState({
          isLoading: false,
          //options: response.data.data.attributes.map(item => { return {label: item.title, value: item.id}})
          options: items //.map(item => { return {name: item.title, id: item.id}})
        });
      } )
      .catch( error => {
        //dispatch(fetchSurveyFailure(error));
        console.log(error);
      } );

  };
}

export default CompanyName;
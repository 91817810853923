import React from 'react';
import {FormGroup, FormText, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import {FaAsterisk} from 'react-icons/fa';

const checkbox = (props) => {
  //console.log(`CHECKBOX value="${props.fieldData.value}", valid="${props.fieldData.valid}"`);
  return (
    <FormGroup check className={props.fieldData.touched && !props.fieldData.valid ? 'alert alert-danger' : ''}>
      <div className="debug">Touched: {props.field.touched ? 'YES' : 'NO' } Valid: {props.field.valid ? 'YES' : 'NO' }</div>
      <p className="debug">{props.field.dataType}/{props.field.fieldType}</p>
      <Input id={'survey-field-' + props.field.fieldKey}
             invalid={props.fieldData.touched && !props.fieldData.valid}
             onChange={(event) => props.changed(props.field.fieldKey, event.target.checked)}
             placeholder={props.field.placeholder}
             type="checkbox"
             valid={props.fieldData.touched && props.fieldData.valid}
             checked={props.fieldData.value}>
      </Input>
      <Label check for={'survey-field-' + props.field.fieldKey}>{props.field.fieldLabel} {props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

checkbox.propTypes = {
  changed: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  fieldData: PropTypes.object.isRequired
};

export default checkbox;
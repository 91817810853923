import React from 'react';

const AddressAutocompleteItem = ({item}) => (
  <div>
    {item.mainText}<br/>
    <small>{item.secondaryText}</small>

  </div>
);


export default AddressAutocompleteItem;
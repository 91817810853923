import React, {Component} from 'react';
import {FormGroup, Row, Col, Input, Label, Button} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.min.css';
import PropTypes from 'prop-types';
import moment from "moment";
import DatePickerCustom from '../../../DatePickerCustom/DatePickerCustom';

class Employment extends Component {

  render() {
    const idPrefix = 'survey-field-' + this.props.fieldKey;
    const options = Object.keys( this.props.types )
      .map( igKey => {
        return <option key={igKey}>{this.props.types[igKey]}</option>
      });

    return (
      <FormGroup>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for={`${idPrefix}-date-${this.props.index}`}>Date *</Label>
              <DatePickerCustom date={this.props.date}
                                id={`${idPrefix}-date-${this.props.index}`}
                                onChange={(date) => this.props.changed(this.props.fieldKey, moment(date).format('YYYY-MM-DD'), this.props.index, 'date')}
                                selected={moment(this.props.date.value).toDate()}/>
              <div className="debug">
                {this.props.date.touched ? 'TOUCHED' : 'not-touched'} &nbsp;
                {this.props.date.valid ? 'VALID' : 'not-valid'}
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className={this.props.total.touched && !this.props.total.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-total-${this.props.index}`}>Total Number of Employees *</Label>
              <Input id={`${idPrefix}-total-${this.props.index}`}
                     invalid={this.props.total.touched && !this.props.total.valid}
                     min="0"
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'total')}
                     placeholder="Number of employees"
                     type="number"
                     value={this.props.total.value}
                     valid={this.props.total.touched && this.props.total.valid} />
              <div className="debug">
                {this.props.total.touched ? 'TOUCHED' : 'not-touched'} &nbsp;
                {this.props.total.valid ? 'VALID' : 'not-valid'}
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className={this.props.classification.touched && !this.props.classification.valid ? 'alert alert-danger' : ''}>
              <Label for={`${idPrefix}-classification-${this.props.index}`}>Classification *</Label>
              <Input id={`${idPrefix}-classification-${this.props.index}`}
                     invalid={this.props.classification.touched && !this.props.classification.valid}
                     type="select"
                     onChange={(event) => this.props.changed(this.props.fieldKey, event.target.value, this.props.index, 'classification')}
                     placeholder="Classification"
                     valid={this.props.classification.touched && this.props.classification.valid}
                     value={this.props.classification.value}>
                <option value="" disabled>Choose a classification</option>
                {options}
              </Input>
              <div className="debug">
                {this.props.classification.touched ? 'TOUCHED' : 'not-touched'} &nbsp;
                {this.props.classification.valid ? 'VALID' : 'not-valid'}
              </div>
            </FormGroup>
          </Col>
          <Col md={1} className="text-center">
            <Button close style={{float: 'none'}} title="Remove field" className="h-100" onClick={() => this.props.removeField(this.props.fieldKey, this.props.index)}/>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

Employment.propTypes = {
  changed: PropTypes.func.isRequired,
  classification: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fieldKey: PropTypes.string.isRequired,
  removeField: PropTypes.func.isRequired,
  total: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
};

export default Employment;
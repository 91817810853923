import React, { Component } from 'react';
import axios from '../../../axios';
import { Alert, Col, Button, Progress, Label, FormGroup, Input, FormText, Row } from 'reactstrap';
import { FaAsterisk } from 'react-icons/fa';

class Attachment extends Component {
  state = {
    selectedFile: null,
    loaded: 0,
    message: null
  };

  checkMimeType = event => {
    const files = event.target.files;

    // list allow mime type
    const types = [
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/zip',
      'application/vnd.ms-excel',
      'text/plain',
      'text/csv'
    ];

    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type, find doesn't match
      if (types.every(type => files[x].type !== type)) {
        // create error message and assign to container
        //event.target.value = null;
        return false;
      }
    }

    return true;
  };

  checkFileSize = event => {
    let files = event.target.files;
    let size = 20000000; // 20MB

    for (let x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        //event.target.value = null;
        return false;
      }
    }
    return true;
  };

  onChangeHandler = event => {
    let files = event.target.files;

    let errorMessage = null;
    if (!this.checkFileSize(event)) {
      errorMessage = 'File is too large. Maximum file size allowed is 20MB';
    } else if (!this.checkMimeType(event)) {
      errorMessage = 'Invalid file type. Please try a different format.';
    }

    // if we encountered an error, display it and abort
    if (errorMessage !== null) {
      this.setState({message: <Alert color="danger"><strong>Error:</strong> {errorMessage}</Alert>});
      return;
    }
    this.setState({message: null});

    // if return true allow to setState
    this.setState({
      selectedFile: files,
      loaded: 0
    }, () => this.onClickHandler());
  };

  onClickHandler = () => {
    const data = new FormData();

    for (let x = 0; x < this.state.selectedFile.length; x++) {
      data.append('file', this.state.selectedFile[x])
    }

    axios.post('/attachments', data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
        })
      },
    })
    .then(res => { // then print response status
      // map returned file meta data into a format we want to store
      const files = res.data.data.map(file => {
        return {
          fileId: file.id,
          contentType: file.attributes.contentType,
          fileName: file.attributes.fileName,
          size: file.attributes.size
        }
      });
      //this.setState({message: <Alert color="success">File uploaded</Alert>});

      this.props.changed(this.props.field.fieldKey, files);
    })
    .catch(err => { // then print response status
      this.setState({message: <Alert color="danger"><strong>Upload failed:</strong> Please try again or try a different file.</Alert>});
      this.removeAttachment();
    })
  };

  removeAttachment = () => {
    this.props.changed(this.props.field.fieldKey, null);
    this.setState({loaded: 0});
  };

  render() {
    return (
      <FormGroup className={this.props.fieldData.touched && !this.props.fieldData.valid ? 'alert alert-danger' : ''}>
        <Label for={'survey-field-' + this.props.field.fieldKey}>
          {this.props.field.fieldLabel}{' '}
          {this.props.field.validation.required ? <FaAsterisk className="required-indicator">*</FaAsterisk> : null}
        </Label>
        {
          !this.props.fieldData.value
            ? <Input id={'survey-field-' + this.props.field.fieldKey}
                     invalid={this.props.fieldData.touched && !this.props.fieldData.valid}
                     multiple
                     placeholder={this.props.field.placeholder}
                     onChange={this.onChangeHandler}
                     type="file"
                     valid={this.props.fieldData.touched && this.props.fieldData.valid}/>
            : <Row>
                <Col md={8}>
                  <Input readOnly value={this.props.fieldData.value[0].fileName}/>
                </Col>
                <Col md={4}>
                  <Button outline size="sm" onClick={this.removeAttachment}>Remove</Button>
                </Col>
              </Row>
        }
        {
          this.state.loaded > 0
            ? <Progress max="100" color="success" value={this.state.loaded}>{Math.round(this.state.loaded)}%</Progress>
            : null
        }
        <FormText color="muted">{this.props.field.helpText}</FormText>
        {this.state.message}
      </FormGroup>
    );
  }
}

export default Attachment;
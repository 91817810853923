import React, { Component } from 'react';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';

class CustomInput extends Component {
  render () {
    return (
      <Input invalid={this.props.invalid}
             onClick={this.props.onClick}
             onChange={() => {}}
             placeholder="Select a date"
             type="text"
             valid={this.props.valid}
             value={this.props.value}/>
    )
  }
}

class DatePickerCustom extends Component {
  render() {
    const customInput = <CustomInput date={moment(this.props.date.value).toDate()}
                                     valid={this.props.date.touched && this.props.date.valid}
                                     invalid={this.props.date.touched && !this.props.date.valid}/>;
    return (
      <DatePicker customInput={customInput}
                  id={this.props.id}
                  onChange={this.props.onChange}
                  selected={this.props.selected}/>
    );
  }
}

DatePickerCustom.propTypes = {
  date: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object
};

export default DatePickerCustom;
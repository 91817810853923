import React from 'react';
import {FormGroup, FormText, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import { FaAsterisk } from 'react-icons/fa';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'

const textInputWysiwyg = (props) => {
  return (
    <FormGroup className={props.fieldData.touched && !props.fieldData.valid ? 'alert alert-danger' : ''}>
      <p className="debug">{props.field.dataType}/{props.field.fieldType}</p>
      <Label for={'survey-field-' + props.field.fieldKey}>{props.field.fieldLabel} {props.field.validation.required ?
        <FaAsterisk className="required-indicator">*</FaAsterisk> : null}</Label>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'
          ]
        }}
        data={props.fieldData.value}
        onInit={editor => {
          // You can store the "editor" and use when it is needed.
          //console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          props.changed(props.field.fieldKey, editor.getData())
          //console.log( { event, editor, data } );
        }}
      />
      <FormText color="muted">{props.field.helpText}</FormText>
    </FormGroup>
  );
};

textInputWysiwyg.propTypes = {
  changed: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  fieldData: PropTypes.object.isRequired,
  inputType: PropTypes.string,
};

export default textInputWysiwyg;